<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="微信h5配置" name="1" class="dialog_box">
                    <el-form class="form-box" ref="weixinsForm" v-bind:rules="rules" v-bind:model="weixinsForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinsForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinsForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinsForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinsForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="证书" prop="certificate">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinsForm.certificate" placeholder="请输入证书"></el-input>
                        </el-form-item> -->
                        <!-- 文件上传 -->
                        <!-- <el-form-item label="证书" prop="certificate">
                            <el-input v-model="weixinsForm.certificate" placeholder="请输入文件链接"></el-input>
                            <el-upload class="avatar-uploader up" action="/i/admin/system/upload/static" :show-file-list="false" :on-success="handleHtmlSuccess" :before-upload="beforeHtmlUpload" name="file" :headers="header">
                                <i v-if="weixinsForm.certificate" class="el-icon-folder-add avatar"></i>
                                <i v-else class="el-icon-upload2 avatar"></i>
                            </el-upload>
                        </el-form-item> -->
                        <el-form-item label>
                            <el-button type="primary" @click="saveweixingForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="微信小程序配置" name="2" class="dialog_box">
                    <el-form class="form-box" ref="xiaochengxusForm" v-bind:rules="rules" v-bind:model="xiaochengxusForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label="审核版本" prop="examine">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.examine" placeholder="请输入审核版本"></el-input>
                        </el-form-item>
                        <el-form-item label="审核id" prop="examine">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="xiaochengxusForm.uid" placeholder="请输入审核版本"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="savexiaochengxuForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="微信app配置" name="3">
                    <el-form class="form-box" ref="wxAppForm" v-bind:rules="rules" v-bind:model="wxAppForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="wxAppForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="wxAppForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="wxAppForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="wxAppForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveWxAppForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="企业微信配置" name="4">
                    <el-form class="form-box" ref="qiyeForm" v-bind:rules="rules" v-bind:model="qiyeForm" label-width="100px">
                        <el-form-item label="keyword" prop="keyword">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qiyeForm.keyword" placeholder="请输入keyword"></el-input>
                        </el-form-item>
                        <el-form-item label="persion" prop="persion">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qiyeForm.persion" placeholder="请输入persion"></el-input>
                        </el-form-item>
                        <el-form-item label="token" prop="token">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="qiyeForm.token" placeholder="请输入token"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveqiyeForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane label="微信外部h5支付配置" name="5" class="dialog_box">
                    <el-form class="form-box" ref="weixinh5Form" v-bind:rules="rules" v-bind:model="weixinh5Form" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinh5Form.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinh5Form.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinh5Form.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinh5Form.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveweixingh5Form">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>


                <el-tab-pane label="微信native支付配置" name="6" class="dialog_box">
                    <el-form class="form-box" ref="weixinNativeForm" v-bind:rules="rules" v-bind:model="weixinNativeForm" label-width="110px">
                        <el-form-item label="appid" prop="appid">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinNativeForm.appid" placeholder="请输入appid"></el-input>
                        </el-form-item>
                        <el-form-item label="appkey" prop="appkey">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinNativeForm.appkey" placeholder="请输入appkey"></el-input>
                        </el-form-item>
                        <el-form-item label="secret" prop="secret">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinNativeForm.secret" placeholder="请输入secret"></el-input>
                        </el-form-item>
                        <el-form-item label="商户号" prop="mch_id">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="weixinNativeForm.mch_id" placeholder="请输入mch_id"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveweixingNativeForm">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
export default {
    name: '',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            activeName: '1',
            menuList: ['设置', '微信配置'],
            qiyeForm: {},
            weixinsForm: {},
            weixinh5Form: {},
            weixinNativeForm: {},
            xiaochengxusForm: {},
            header: {},
            weixinsFormRules: {},
            wxAppForm: {},
            rules: {
                appkey: [
                    {
                        required: true,
                        message: 'appkey 不能为空',
                        trigger: 'blur'
                    }
                ],
                mch_id: [
                    {
                        required: true,
                        message: 'mch_id不能为空',
                        trigger: 'blur'
                    }
                ],
                key: [
                    {
                        required: true,
                        message: 'key不能为空',
                        trigger: 'blur'
                    }
                ],
                keyword: [
                    {
                        required: true,
                        message: 'keyword不能为空',
                        trigger: 'blur'
                    }
                ],
                persion: [
                    {
                        required: true,
                        message: 'persion不能为空',
                        trigger: 'blur'
                    }
                ],
                uid: [
                    {
                        required: true,
                        message: '审核id不能为空',
                        trigger: 'blur'
                    }
                ],
                token: [
                    {
                        required: true,
                        message: 'token不能为空',
                        trigger: 'blur'
                    }
                ],
                examine: [
                    {
                        required: true,
                        message: '审核版本不能为空',
                        trigger: 'blur'
                    }
                ],
                v: [
                    {
                        required: true,
                        message: 'v不能为空',
                        trigger: 'blur'
                    }
                ],
                appid: [
                    {
                        required: true,
                        message: 'appid不能为空',
                        trigger: 'blur'
                    }
                ],
                secret: [
                    {
                        required: true,
                        message: '版本不能为空',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.weixingForm()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                case '1':
                    this.weixingForm()
                    break
                case '2':
                    this.xiaochengxuForm()
                    break
                case '3':
                    this.loadWxAppForm()
                    break
                case '4':
                    this.loadqiyeForm()
                    break
                case '5':
                    this.weixingh5Form()
                    break
                case '6':
                    this.weixingNativeForm()
                    break
            }
        },
        // 企业微信配置
        loadqiyeForm() {
            this.loading = true;
            request.get('/system/enterprise/weixin/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.qiyeForm = ret.data;
                    let item = ret.data.persion.join()
                    this.$set(this.qiyeForm, 'persion', item)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信配置
        weixingForm() {
            this.loading = true;
            request.get('/system/weixin/h5').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.weixinsForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信外部h5支付配置
        weixingh5Form() {
            this.loading = true;
            request.get('/system/weixin/h5/web/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.weixinh5Form = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信native支付配置
        weixingNativeForm() {
            this.loading = true;
            request.get('/system/weixin/native/list').then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.weixinNativeForm = ret.data;
                    console.log(this.weixinNativeForm)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //小程序配置
        xiaochengxuForm() {
            this.loading = true;
            request.get('/system/weixin/mp').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.xiaochengxusForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信app配置
        loadWxAppForm() {
            this.loading = true;
            request.get('/system/weixin/app/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.wxAppForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 企业微信编辑
        saveqiyeForm() {
            this.loading = true;
            request.post('/system/enterprise/weixin/edit', this.qiyeForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.qiyeForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadqiyeForm()
                    this.$refs.qiyeForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信编辑
        saveweixingForm() {
            this.loading = true;
            request.post('/system/weixin/h5/edit', this.weixinsForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.weixinsForm = ret.data;
                    this.$message.success('编辑成功');
                    this.weixingForm()
                    this.$refs.weixinsForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信外部h5支付编辑
        saveweixingh5Form() {
            this.loading = true;
            request.post('/system/weixin/h5/web/edit', this.weixinh5Form).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.weixinh5Form = ret.data;
                    this.$message.success('编辑成功');
                    this.weixingh5Form()
                    this.$refs.weixinh5Form.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信native支付编辑
        saveweixingNativeForm() {
            this.loading = true;
            request.post('/system/weixin/native/edit', this.weixinNativeForm).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.weixinNativeForm = ret.data;
                    this.$message.success('编辑成功');
                    this.weixingNativeForm()
                    this.$refs.weixinNativeForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信小程序编辑
        savexiaochengxuForm() {
            this.loading = true;
            request.post('/system/weixin/mp/edit', this.xiaochengxusForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.xiaochengxusForm = ret.data;
                    this.$message.success('编辑成功');
                    this.xiaochengxuForm()
                    this.$refs.xiaochengxusForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //微信app编辑
        saveWxAppForm() {
            this.loading = true;
            request.post('/system/weixin/app/edit', this.wxAppForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.wxAppForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadWxAppForm()
                    this.$refs.wxAppForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 微信h5文件上传
        handleHtmlSuccess(res, file) {
            if (res.code == 1) {
                // loads.close()
                this.$set(this.weixinsForm, 'certificate', res.data.uri);
            }
        },
        //文件上传
        beforeHtmlUpload(file) {
            // loads.open('.avatar-uploader .el-upload', '上传中')
            // let fileName = file.name
            // let pos = fileName.lastIndexOf('.')
            // let lastName = fileName.substring(pos, fileName.length)
            // console.log(66666, lastName)
            // if (lastName.toLowerCase() !== '.html') {
            //     this.$message.error('不支持此文件格式!');
            // }
            // Loading.service({ text: '文件上传中' });
        },
        // 微信小程序文件上传
        handleXiaoHtmlSuccess(res, file) {
            if (res.code == 1) {
                console.log(111111)
                // loads.close()
                this.$set(this.xiaochengxusForm, 'certificate', res.data.uri);
            }
        },
        beforeXiaoHtmlUpload(file) {
            // loads.open('.avatar-uploader .el-upload', '上传中')
            // const _this = this
            // return deal.imageFormat(file, _this, 300)
        },
    }
}
</script>
<style scoped>
.el-form-item--small.el-form-item {
    margin-top: 30px;
}

.el-input--small {
    margin-left: 10px;
}

.el-button {
    /* margin-left: 210px; */
    margin: 60px 0 80px 0;
}

.containet {
    background: #ffffff;
}

.form-box {
    width: 100%;
}

.form-input >>> .el-input__inner {
    width: 900px;
    border-radius: 4px;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
</style>
